@import url('https://fonts.googleapis.com/css2?family=Montaga&family=Montserrat:wght@300&family=Poppins:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Montaga', serif;
}

@layer base {
  html {
    scroll-behavior: smooth;
  }
  .finput {
    @apply block px-4 py-2 rounded-md  w-full focus:outline-yellow-900 focus:outline-1 0md:mb-3;
  }
  .icon {
    @apply text-5xl border-dashed border-2 p-2 rounded-full border-yellow-400 text-yellow-500;
  }
  .contactbox {
    @apply shadow-lg text-center py-4 font-serif mb-4 rounded-md px-5  dark:bg-blue-950;
  }
  textarea {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    @apply block px-4 py-2 w-full rounded-md focus:outline-orange-900 focus:outline-1;
  }
}
